<template>
  <div
    class="flex justify-center min-h-screen px-2  bg-white body-bg pt-5"
  >
    <div v-if="auth" class="container flex overflow-hidden">
      <router-view class="flex-1 overflow-hidden" />
    </div>
    <div v-else class="">
        <div
    class="flex items-center justify-center min-h-screen px-4 py-12 bg-gray-50 sm:px-6 lg:px-8"
  >
    <div class="w-full max-w-md space-y-8">
      <div>
        <!-- <img
          class="w-auto h-12 mx-auto"
          src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
          alt="Workflow"
        /> -->
        <div class="flex justify-items-center">
          <w-logo-orsai class="w-64 mx-auto text-gray-900"/>
        </div>
        <!-- <h2 class="mt-6 text-3xl font-extrabold text-center text-gray-900">
          Administrador Orsai
        </h2> -->
        <!-- <p class="mt-2 text-sm text-center text-gray-600">
          Or
          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
            start your 14-day free trial
          </a>
        </p> -->
      </div>
      <form
        class=" space-y-6"
        action="#"
        method="POST"
        @submit.prevent="login()"
      >
        <input type="hidden" name="remember" value="true" />
        <div class="-space-y-px rounded-md shadow-sm">
          <div>
            <label for="key" class="sr-only"
              >Clave</label
            >
            <input
              v-model="key"
              id="key"
              name="password"
              type="password"
              required
              class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Clave de ingreso"
            />
          </div>
        </div>

        <div v-if="error" class="flex items-center justify-between">
          <div  class="text-sm">
            <a href="#" class="font-medium text-red-600">
              Los datos son incorrectos
            </a>
          </div>
        </div>
        <div>
          <div class="font-medium text-red-500"></div>
          <button
            type="submit"
            class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <!-- Heroicon name: lock-closed -->
              <svg
                v-if="!busy"
                class="w-5 h-5 text-indigo-500 group-hover:text-indigo-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"
                />
              </svg>

              <svg
                v-if="busy"
                class="w-5 h-5 ml-1 mr-2 text-white transition duration-150 ease-in-out animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-50"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </span>
            Ingresar
          </button>
        </div>
      </form>
    </div>
  </div>
    </div>
  </div>
</template>

<script>
const PASSWORD = "FlorCuentos2022";
export default {
  data(){
    return {
      auth: null,
      key: "",
      busy: null,
      error: null
    }
  },  
  created () {
    const rememberMe = localStorage.getItem("rememberMe");
    if(rememberMe === PASSWORD)
      this.auth = true;
  },
  methods: {
    login(){
      this.error = null
      if(this.key === PASSWORD)
      {
        localStorage.setItem('rememberMe', this.key)
        return this.auth = true;
        }
      else this.error = "Clave de acceso incorrecta"
    }
  }
}
</script>