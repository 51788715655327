<template>
  <div>
    <svg
      fill="currentColor"
      width="100%"
      viewBox="0 0 102 31"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      class="font-georgia"
    >
      <g
        stroke="none"
        stroke-width="1"
        fill="currentColor"
        fill-rule="evenodd"
        font-family="Georgia"
        font-size="34"
        font-weight="300"
      >
        <text id="Orsai" fill="currentColor">
          <tspan
            font-family="Georgia"
            font-weight="800"
            letter-spacing="-1"
            x="0"
            y="90%"
          >
            Orsa
          </tspan>
          <tspan
            x="79%"
            y="90%"
            class="logo-red"
            font-family="Georgia"
            font-weight="800"
          >
            i
          </tspan>
        </text>
      </g>
    </svg>

    <!-- Agregar segunda fila -->
    <div v-if="subtexto" class="text-xs">{{ subtexto }}</div>
  </div>
</template>

<script>
export default {
  props: {
    subtexto: {
      type: String,
      default: null,
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;900&amp;display=swap");
.font-barlow,
.font-barlow g,
.font-barlow path,
.font-barlow tspan {
  font-family: "Barlow", sans-serif;
}
.logo {
  /* width: 230px; */
  animation: 1s appear;
  margin: auto;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
}
.logo-orsai {
  width: 130px;
}
.logo-red {
  color: red;
}
</style>
